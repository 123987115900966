<template>
  <div>
    <headTop :currentrStr="currentrStr" sfType="老师"></headTop>

    <div class="about common-width">
      <div class="demo-input-suffix">
        <!-- <el-divider content-position="left">班级管理</el-divider> -->
        <div style="margin-bottom: 10px; overflow: hidden">
          <a-button type="primary" style="float: right" @click="createFun">
            创建班级
          </a-button>
          <a-button
            type="danger"
            style="float: right; margin-right: 10px"
            @click="getCd('','存档列表')"
          >
            存档列表
          </a-button>
          <!---创建班级-->
          <a-modal
            v-model="visibleCreate"
            title="创建班级"
            ok-text="确认创建"
            cancel-text="取消"
            @ok="hideModal"
          >
            <a-form-model
              class="reg"
              ref="ruleForm"
              :model="form"
              :rules="rules"
              :label-col="labelCol"
              :wrapper-col="wrapperCol"
            >
              <a-form-model-item
                ref="title"
                label="班级名称"
                prop="title"
                :labelCol="{ span: 24, offset: 0 }"
                labelAlign="left"
              >
                <a-input
                  placeholder="请输入班级名称"
                  v-model="form.title"
                ></a-input>
              </a-form-model-item>
              <a-form-model-item
                ref="grade"
                label="年级"
                prop="grade"
                :labelCol="{ span: 24, offset: 0 }"
                labelAlign="left"
              >
                <a-select v-model="form.grade" placeholder="请选择年级">
                  <a-select-option value="1"> 一年级 </a-select-option>
                  <a-select-option value="2"> 二年级 </a-select-option>
                  <a-select-option value="3"> 三年级 </a-select-option>
                  <a-select-option value="4"> 四年级 </a-select-option>
                  <a-select-option value="5"> 五年级 </a-select-option>
                  <a-select-option value="6"> 六年级 </a-select-option>
                  <a-select-option value="7"> 七年级 </a-select-option>
                  <a-select-option value="8"> 八年级 </a-select-option>
                  <a-select-option value="7"> 七年级 </a-select-option>
                  <a-select-option value="9"> 九年级 </a-select-option>
                  <a-select-option value="10"> 十年级 </a-select-option>
                  <a-select-option value="11"> 十一年级 </a-select-option>
                  <a-select-option value="12"> 十二年级 </a-select-option>
                  <a-select-option value="other"> 其它年级 </a-select-option>
                </a-select>
              </a-form-model-item>
              <a-form-model-item
                ref="school"
                label="学校"
                prop="school"
                :labelCol="{ span: 24, offset: 0 }"
                labelAlign="left"
              >
                <a-input
                  placeholder="请输入学校"
                  v-model="form.school"
                ></a-input>
              </a-form-model-item>
              <a-form-model-item
                ref="course"
                label="课程"
                prop="course"
                :labelCol="{ span: 24, offset: 0 }"
                labelAlign="left"
              >
                <a-select v-model="form.course" placeholder="请选择课程">
                  <a-select-option value="1"> 编程课 </a-select-option>
                  <a-select-option value="2"> 图形化 </a-select-option>
                  <a-select-option value="3"> 基础化 </a-select-option>
                </a-select>
              </a-form-model-item>
            </a-form-model>
          </a-modal>
          <!---添加学生-->
          <a-modal
            v-model="visibleCreateS"
            title="添加学生"
            ok-text="确认添加"
            cancel-text="取消"
            @ok="hideModalS"
          >
         
            <a-form-model
              class="reg"
              ref="ruleFormS"
              :model="formS"
              :rules="rulesS"
              :label-col="labelCol"
              :wrapper-col="wrapperCol"
            >
              <a-form-model-item
                ref="username"
                label="学生账号"
                prop="username"
                :labelCol="{ span: 24, offset: 0 }"
                labelAlign="left"
              >
                <a-input
                  placeholder="请输入学生账号"
                  v-model="formS.username"
                ></a-input>
              </a-form-model-item>
            </a-form-model>
          </a-modal>
          <!---点击班级名称-->
          <a-modal
            :footer="null"
            v-model="visibleCreateN"
            :title="`${titleName} - 学生管理`"
            :width="widthL ? '99%' : '60%'"
            cancel-text="取消"
          >
          <el-button type="primary" size="small" @click="addS(idName, titleName)" style="float:right;margin-bottom:10px;"
              >添加学生</el-button
            >
            <el-table :data="tableDataS" border style="width: 100%">
              <!-- <el-table-column fixed prop="classname" label="班级名称">
              </el-table-column> -->

              <el-table-column prop="username" label="用户名">
              </el-table-column>
              <el-table-column prop="name" label="姓名"> </el-table-column>
              <el-table-column prop="sex" label="性别"> </el-table-column>

              <el-table-column fixed="right" label="操作" width="100">
                <template slot-scope="scope">
                  <a-popconfirm
                    title="确定删除此学生吗？"
                    ok-text="确定"
                    cancel-text="取消"
                    @confirm="confirmFunS(scope.row.id, scope.row.classname)"
                  >
                    <el-button type="text" size="small">删除</el-button>
                  </a-popconfirm>

                  <!-- <el-button type="text" size="small">编辑</el-button> -->
                </template>
              </el-table-column>
            </el-table>
            <el-pagination
              small
              layout="prev, pager, next"
              :total="total"
              @current-change="pageFun"
             
            >
            </el-pagination>
          </a-modal>
        <!---存档班级列表-->
              <a-modal
            :footer="null"
            v-model="visibleCreateC"
            title="存档列表"
            :width="widthL ? '99%' : '60%'"
            cancel-text="取消"
          >
      
          <el-table :data="tableDataC" border style="width: 100%">
            <el-table-column fixed prop="title" label="班级名称">
              <!-- <template slot-scope="scope">
                <a
                  href="javascript:;"
                  @click="titleFun(scope.row._id, scope.row.title)"
                  >{{ scope.row.title }}</a
                >
              </template> -->
            </el-table-column>
            <el-table-column prop="grade" label="年级"> </el-table-column>
            <el-table-column prop="school" label="学校"> </el-table-column>
            <el-table-column prop="course" label="课程"> </el-table-column>
            <el-table-column prop="number" label="班级人数">
            </el-table-column>
            <el-table-column prop="joincode" label="邀请码">
            </el-table-column>
            

            <el-table-column fixed="right" label="操作" width="100">
              <template slot-scope="scope">
                <a-popconfirm
                  title="确定恢复？"
                  ok-text="确定"
                  cancel-text="取消"
                  @confirm="hfFun(scope.row._id)"
                >
                  <el-button type="text" size="small">恢复</el-button>
                </a-popconfirm>
             


                <!-- <el-button type="text" size="small">编辑</el-button> -->
              </template>
            </el-table-column>
          </el-table>
            <!-- <el-pagination
              small
              layout="prev, pager, next"
              :total="total"
              @current-change="pageFun"
             
            >
            </el-pagination> -->
          </a-modal>
        </div>

        <div style="margin-top: 10px">
          <!-- <a-table
              bordered
              :columns="columns"
              :data-source="data"
              :scroll="{ x: 1000, y: 300 }"
            >
            </a-table> -->

          <el-table :data="tableData" border style="width: 100%">
            <el-table-column fixed prop="title" label="班级名称">
              <template slot-scope="scope">
                <a
                  href="javascript:;"
                  @click="titleFun(scope.row._id, scope.row.title)"
                  >{{ scope.row.title }}</a
                >
              </template>
            </el-table-column>
            <el-table-column prop="grade" label="年级"> </el-table-column>
            <el-table-column prop="school" label="学校"> </el-table-column>
            <el-table-column prop="course" label="课程"> </el-table-column>
            <el-table-column prop="number" label="班级人数">
            </el-table-column>
            <el-table-column prop="joincode" label="邀请码">
              <template slot-scope="scope">
                <a
                  href="javascript:;"
                  @click="_copy(scope.row.joincode,scope.row.title )"
                  >{{ scope.row.joincode }}</a
                >
              </template>
            </el-table-column>
            <el-table-column fixed="right" label="操作" width="170">
              <template slot-scope="scope">
                <a-popconfirm
                  title="确定删除？"
                  ok-text="确定"
                  cancel-text="取消"
                  @confirm="confirmFun(scope.row._id)"
                >
                  <el-button type="text" size="small">删除</el-button>
                </a-popconfirm>
                <a-popconfirm
                  title="确定存档？"
                  ok-text="确定"
                  cancel-text="取消"
                  @confirm="setCd(scope.row._id, scope.row.title)"
                >
                <el-button
                  type="text"
                  size="small"
          
                  >存档</el-button
                >
                </a-popconfirm>


                <!-- <el-button type="text" size="small">编辑</el-button> -->
              </template>
            </el-table-column>
          </el-table>
        </div>
        <!-- <el-divider content-position="left" style="margin-top: 10px"
          >学生管理</el-divider
        > -->
        <div style="margin-bottom: 10px; overflow: hidden">
          <!-- <a-button type="primary" style="float: right" @click="createFun">
            创建
          </a-button> -->
        </div>

        <div style="margin-top: 10px">
          <!-- <a-table
              bordered
              :columns="columns"
              :data-source="data"
              :scroll="{ x: 1000, y: 300 }"
            >
            </a-table> -->
        </div>
      </div>
      <div class="boxT"></div>
    </div>
  </div>
</template>
  <script>
// import banner from "../components/banner.vue";
import headTop from "../components/header.vue";

export default {
  name: "list",
  components: { headTop },
  data() {
    return {
      total: 0,
      idC: "",
      labelCol: { span: 4 },
      wrapperCol: { span: 24 },
      tabPosition: "left",
      currentrStr: ["classes"],
      ModalText: "Content of the modal",
      visible: false,
      visibleCreate: false,
      visibleCreateS: false,
      confirmLoading: false,
      visibleCreateN: false,
      visibleCreateC: false,
      widthL: null,
      idName: "",
      dialogFormVisible: false,
      titleName: "",
      cdTitle:'',
      formLabelWidth: "120px",
      form: {
        title: "",
        course: undefined,
        school: "",
        grade: undefined,
      },
      formS: {
        username: "",
      },
      addId: "",
      addTitle: "",
      tableData: [],
      tableDataS: [],
      tableDataC: [],
      rules: {
        title: [
          {
            required: true,
            message: "请输入班级名称",
            trigger: "blur",
          },
        ],
      },
      bjId: "",
      rulesS: {
        username: [
          {
            required: true,
            message: "请输入学生账号",
            trigger: "blur",
          },
        ],
      },
    };
  },
  components: {
    headTop,
  },
  created: function () {
    this.initS();
  },
  mounted: function () {
    if( window.localStorage.getItem('anjing-token-group') !='teacher'){
      this.$router.push({
        path: "./index",
      });
    }
    window.onresize = () => {
      return (() => {
        if (document.body.clientWidth <= 800) {
          this.widthL = true;
        } else {
          this.widthL = false;
        }
      })();
    };
  },
  methods: {
    _copy(context,title) {
      // 创建输入框元素
      let oInput = document.createElement("input");
      // 将想要复制的值
      oInput.value = context;
      // 页面底部追加输入框
      document.body.appendChild(oInput);
      // 选中输入框
      oInput.select();
      // 执行浏览器复制命令
      document.execCommand("Copy");
      // 弹出复制成功信息
      this.$message.success(title+" - 邀请码复制成功");
      // 复制后移除输入框
      oInput.remove();
    },
    addS(id, title) {
      this.visibleCreateS = true;
      this.addId = id;
      this.addTitle = title;
      this.formS.username = ''
    },
    titleFun(id, title) {
      console.log(id);
      this.visibleCreateN = true;
      this.addstudentFun(id, title);
      this.titleName = title;
      this.idName = id;
    },
    initS() {
      let data = {
        op: "getlist",
      };
      let _this = this;
      this.$ajax
        .post(this.url + "teacher/classes.do", _this.Qs.stringify(data))
        .then(function (response) {
          console.log(response);
          if (response.data.value == "ok") {
            _this.tableData = response.data.data;

            // _this.columns = response.data.data;
          } else {
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    pageFun(page, pageSize) {
        console.log(page)
      this.addstudentFun(this.idName, "", page);
    },
    addstudentFun(id, title, page = 1) {
      let data = {
        op: "getstudents",
        _id: id,
        page: page,
      };
      let _this = this;
      this.$ajax
        .post(this.url + "teacher/classes.do", _this.Qs.stringify(data))
        .then(function (response) {
          console.log(response);
          if (response.data.value == "ok") {
            _this.tableDataS = response.data.data;
            _this.bjId = response.data._id;
            _this.total = response.data.count;
            if (_this.tableDataS.length == 0) {
              //   _this.$message.info(title + "暂无学生，请先添加学生再查看！");
            }

            // _this.columns = response.data.data;
          } else {
            _this.$message.error(response.data.msg);
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    createFun() {
      this.visibleCreate = true;
      this.form.title = '';
      this.form.grade = undefined;
      this.form.school = '';
      this.form.course = undefined;

    },
    // handleSubmit(e) {
    //   console.log(this.formInline);
    // },
    showModal() {
      this.visible = true;
    },
    setCd(id){
        let _this = this;
          let data = {
            op:'archive',
            _id:id
          }
          this.$ajax
            .post(this.url + "teacher/classes.do", _this.Qs.stringify(data))
            .then(function (response) {
              console.log(response);
              if (response.data.value == "ok") {
                // _this.tableData = response.data.data
                // _this.columns = response.data.data;
                _this.initS();
                _this.$message.success(response.data.msg);
              }  else {
                _this.$message.error(response.data.msg);
              }
            })
            .catch(function (error) {
              console.log(error);
            });
    },
    hfFun(id){
        let _this = this;
          let data = {
            op:'outarchive',
            _id:id
          }
          this.$ajax
            .post(this.url + "teacher/classes.do", _this.Qs.stringify(data))
            .then(function (response) {
              console.log(response);
              if (response.data.value == "ok") {
                // _this.tableDataC = response.data.data
                // _this.columns = response.data.data;
                _this.initS();
                _this.getCd();
                _this.$message.success(response.data.msg);
              }  else {
                _this.$message.error(response.data.msg);
              }
            })
            .catch(function (error) {
              console.log(error);
            });
    },
    getCd(){
     
        this.visibleCreateC = true;
       
          let _this = this;
          let data = {
            op:'archivelist'
          }
          this.$ajax
            .post(this.url + "teacher/classes.do", _this.Qs.stringify(data))
            .then(function (response) {
              console.log(response);
              if (response.data.value == "ok") {
                _this.tableDataC = response.data.data
                // _this.columns = response.data.data;
                // _this.initS();
                // _this.$message.success(response.data.msg);
              }  else {
                _this.$message.error(response.data.msg);
              }
            })
            .catch(function (error) {
              console.log(error);
            });


    },
    hideModal() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.visibleCreate = false;
          let data = {
            op: "create",
            title: this.form.title,
            grade: this.form.grade || "",
            school: this.form.school,
            course: this.form.course || "", //课程id
          };
          let _this = this;
          this.$ajax
            .post(this.url + "teacher/classes.do", _this.Qs.stringify(data))
            .then(function (response) {
              console.log(response);
              if (response.data.value == "ok") {
                // _this.tableData = response.data.data
                // _this.columns = response.data.data;
                _this.initS();
                _this.$message.success(response.data.msg);
              } else if (response.data.value == "sessionerror") {
                _this.$message.error(response.data.msg);
              } else {
                _this.$message.error(response.data.msg);
              }
            })
            .catch(function (error) {
              console.log(error);
            });
        }
      });
    },
    // 添加学生
    hideModalS() {
      this.$refs.ruleFormS.validate((valid) => {
        if (valid) {
          this.visibleCreateS = false;
     
          let data = {
            op: "addstudent",
            _id: this.addId,
            username: this.formS.username,
          };
          let _this = this;
          this.$ajax
            .post(this.url + "teacher/classes.do", _this.Qs.stringify(data))
            .then(function (response) {
              console.log(response);
              if (response.data.value == "ok") {
                // _this.tableData = response.data.data
                // _this.columns = response.data.data;
                _this.addstudentFun(_this.addId, _this.addTitle);
                _this.$message.success(response.data.msg);
             
              } else {
                _this.$message.error(response.data.msg);
              }
            })
            .catch(function (error) {
              console.log(error);
            });
        }
      });
    },
    handleOk(e) {
      this.ModalText = "The modal will be closed after two seconds";
      this.confirmLoading = true;
      setTimeout(() => {
        this.visible = false;
        this.confirmLoading = false;
      }, 2000);
    },
    handleCancel(e) {
      console.log("Clicked cancel button");
      this.visible = false;
    },
    onChange() {},
    // handleClick(){},
    confirmFunS(id, title) {
      console.log(id);
      let _this = this;
      let data = {
        op: "delstudent",
        _id: this.bjId,
        uid: id,
      };
      this.$ajax
        .post(this.url + "teacher/classes.do", _this.Qs.stringify(data))
        .then(function (response) {
          console.log(response);
          if (response.data.value == "ok") {
            // _this.tableData = response.data.data
            // _this.columns = response.data.data;
            _this.addstudentFun(_this.bjId, title);
            _this.$message.success(response.data.msg);
          } else {
            _this.$message.error(response.data.msg);
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    confirmFun(id) {
      let _this = this;
      let data = {
        op: "drop",
        _id: id,
      };
      this.$ajax
        .post(this.url + "teacher/classes.do", _this.Qs.stringify(data))
        .then(function (response) {
          console.log(response);
          if (response.data.value == "ok") {
            // _this.tableData = response.data.data
            // _this.columns = response.data.data;
            _this.initS();
            _this.$message.success(response.data.msg);
          } else {
            _this.$message.error(response.data.msg);
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    handleClick(id) {
      // let _this = this;
      // console.log(id);
      // _this
      //   .$confirm("此操作将永 久删除该文件, 是否继续?", "提示", {
      //     confirmButtonText: "确定",
      //     cancelButtonText: "取消",
      //     type: "warning",
      //   })
      //   .then(() => {
      //     _this.$message({
      //       type: "success",
      //       message: "删除成功!",
      //     });
      //   })
      //   .catch(() => {
      //     _this.$message({
      //       type: "info",
      //       message: "已取消删除",
      //     });
      //   });
    },
  },
};
</script>
  <style  lang="scss" scoped>
.boxT {
  width: 1180px;
  margin: 20px auto;
  .a-input {
    width: 20%;
    margin-right: 10px;
  }
  .title {
    padding: 20px 0 20px;
    font-size: 18px;
    font-weight: bold;
    text-align: left;
  }
}
.el-divider--horizontal {
  margin: 35px 0 14px;
}
// .ant-modal-mask {
//   background-color: rgba(0, 0, 0, 0.1);
// }
.el-divider {
  background-color: #ffe9b5;
  height: 5px;
}
// .ant-modal-footer {
//   display: none;
// }
.el-pagination {
  text-align: right;
  margin-top: 10px;
}
.about {
  margin-top: 30px !important;
}
@media (max-width: 800px) {
  .boxT {
    width: 95%;
  }
}
</style>
  